const Routes = {
    unAuthenticatedRoutes: [
        "/login",
        "/signup",
        "/forgot-password",
        "/new-password",
        "/event",
        "/careers"
    ],
    both: [
        "/",
        "/privacy-policy",
        "/cookie-policy",
        "/terms-and-conditions",
        "/cancellation-refund-policy",
        "/about-us",
        "/logos",
        "/awards",
        "/",
        "/error",
        "/_error",
        "/contact-us",
        "/resources",
        "/communities",
        "/community",
        "/usecases",
        "/resources/[resourcesId]",
        "/events/[[...params]]",
        "/404",
        "/cards",
        "/pricing",
        "/code-of-conduct",
        "/[eventUrl]",
        "/checkout/[eventUrl]",
        "/checkout/ticket-confirmation",
        "/widget/[eventUrl]",
        "/widget/ticket-confirmation",
        "/widget/single/[eventUrl]",
        "/widget/agenda/[eventUrl]",
        "/unsubscribe/[userID]",
        "/professional-template",
        "/trendy-template",
        "/minimal-template",
        "/features",
        "/checkinapp",
        "/sponsorapp",
        "/attendeeapp",
        "/aibooth",
        "/aigallery",
        "/exhibitor-portal",
        "/payments-settlements",
        "/ticketing",
        "/stripe-connect",
        "/tap-payments",
        "/whitelabeling",
        "/developers",
        "/checkout/stripe/[eventUrl]",
        "/responsible-disclosure",
        "/g/[communityUrl]",
        "/pitchground",
        "/checkout/stripe/[eventUrl]",
        "/close-tap",
        "/checkout/CustomRedirects",
        "/ui-standup",
        "/ui-workspace",
        "/outage",
        "/staging-db-status",
        "/dev-db-status",
        "/case-studies",
        "/linkedin-share-poc",
        "/linkedin-share",
        "/faq"
    ],
    authenticatedRoutes: [
        "/profile",
        "/bookings",
        "/email-verification/success",
        "/email-verification/failed"
    ]
};

export default Routes;